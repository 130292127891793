import React, { useState, useEffect, createContext } from 'react';

// Apollo
import { useQuery } from '@apollo/client';
import { CUSTOM_REPOSITORY } from './gql-select-repository-items';
import { CUSTOM_REPOSITORY_RESULTS } from 'views/authenticated/repositories/custom-repositories/custom-repository/gql-custom-repository';

// Services
import {
    ICustomRepository, IRepositoryItems, IRepositoryItem, ICustomRepositoryResults,
    ISelectOptionWithLinkItems, IAtypicalRepository, IRepositoryLink, ISimpleResult,
} from 'services/interfaces';
import { EEvaluationType, ERepositoryItemType, ERepositoryType, EStepType } from 'services/enums';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import { getRepositoryItemType } from 'repository-items/utils';

// Views
import SelectRepositoryType from './01-select-repository-type';
import SelectRepositoryItems from './02-select-repository-items';
import RatingRepositoryItems from './03-rating-repository-items';

/* * *
* Context
*/

export interface IRepositoryItemsFormContext {
    setContext: React.Dispatch<React.SetStateAction<IState>>;
    setRepositoryItems: (repositoryItems: IRepositoryItems) => void;
    stepType: EStepType | EEvaluationType | 'SEARCH';
    repositoryType: ERepositoryType | null;
    repositoryItemType: ERepositoryItemType | null;
    atypicalRepositorySelectOption: ISelectOptionWithLinkItems | null;
    atypicalRepository: IAtypicalRepository | null;
    atypicalRepositoryResults: ISimpleResult[];
    filterItemsRelatedToActivities: string;
    filteredActivityIds: string[];
    customSkills: IRepositoryItem[],
    descriptors: IRepositoryItem[],
    activities: IRepositoryItem[],
    tasks: IRepositoryItem[],
    atypicalItems: IRepositoryItem[],
    linkItems: IRepositoryItem[],
    roadmap: ({ id: string, learnerId: string }) | undefined;
    enabledActivityRepository: boolean;
    enabledSkillRepositoryItems: boolean;
    enabledObservableElements: boolean;
    repositoryLinks: IRepositoryLink[];
}

export const RepositoryItemsFormContext = createContext<IRepositoryItemsFormContext | null>(null);

/* * *
* State
*/

export interface IState {
    repositoryType: ERepositoryType | null;
    atypicalRepositorySelectOption: ISelectOptionWithLinkItems | null;
    atypicalRepository: IAtypicalRepository | null;
    atypicalRepositoryResults: ISimpleResult[];
    filterItemsRelatedToActivities: string;
    filteredActivityIds: string[];
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- REPOSITORY ITEMS FORM ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function RepositoryItemsForm({
    customRepositoryId,
    stepType,
    setRepositoryItems,
    customSkills,
    descriptors,
    activities,
    tasks,
    atypicalItems,
    startPosition = 1,
    defaultRepositoryType,
    defaultAtypicalRepositoryId,
    roadmap,
    enabledRating = false,
    enabledActivityRepository = true,
    enabledSkillRepositoryItems = false,
    enabledObservableElements = false,
    linkItems,
} : {
    customRepositoryId: string,
    stepType: EStepType | EEvaluationType | 'SEARCH',
    setRepositoryItems: (repositoryItems: IRepositoryItems) => void,
    customSkills?: IRepositoryItem[],
    descriptors?: IRepositoryItem[],
    activities?: IRepositoryItem[],
    tasks?: IRepositoryItem[],
    atypicalItems?: IRepositoryItem[],
    startPosition?: number,
    defaultRepositoryType?: ERepositoryType,
    defaultAtypicalRepositoryId?: string,
    roadmap?: ({ id: string, learnerId: string }),
    enabledRating?: boolean,
    enabledActivityRepository?: boolean,
    enabledSkillRepositoryItems?: boolean,
    enabledObservableElements?: boolean,
    linkItems?: IRepositoryItem[],
}) {

    const [state, setState] = useState<IState>({
        repositoryType: null,
        atypicalRepositorySelectOption: null,
        atypicalRepository: null,
        atypicalRepositoryResults: [],
        filterItemsRelatedToActivities: '0',
        filteredActivityIds: [],
    });

    const { loading, error, data } = useQuery<{ customRepository: ICustomRepository }>(CUSTOM_REPOSITORY, {
        variables: { id: customRepositoryId },
    });

    const { loading: loadingResults, error: errorResults, data: dataResults } = useQuery<{ customRepositoryResults: ICustomRepositoryResults }>(CUSTOM_REPOSITORY_RESULTS, {
        variables: {
            learnerId: roadmap?.learnerId,
            customRepositoryId: customRepositoryId,
            roadmapId: roadmap?.id,
        },
        skip: roadmap == null,
    });

    useEffect(() => {
        if (data?.customRepository) {

            let repositoryType : ERepositoryType;
            let atypicalRepositorySelectOption : ISelectOptionWithLinkItems | null = null;

            if (defaultRepositoryType) {
                repositoryType = defaultRepositoryType;
            }
            else if (stepType === EStepType.SUPPORT) {
                repositoryType = ERepositoryType.ATYPICAL;
            }
            else if (enabledActivityRepository && data.customRepository?.activityRepository) {
                repositoryType = ERepositoryType.ACTIVITY;
            }
            else {
                repositoryType = ERepositoryType.CUSTOM;
            }
            
            if (
                defaultAtypicalRepositoryId
                && data.customRepository?.repositoryLinks
                && data.customRepository.repositoryLinks.length > 0
            ) {

                let link = data.customRepository.repositoryLinks.find(l => l.atypicalRepository?.id === defaultAtypicalRepositoryId);

                if (link) {
                    atypicalRepositorySelectOption = ({
                        value: link.atypicalRepository?.id,
                        label: link.atypicalRepository?.name,
                        linkItems: link.linkItems,
                    });
                }
            }

            setState(prevState => ({
                ...prevState,
                repositoryType,
                atypicalRepositorySelectOption,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, stepType, defaultRepositoryType, defaultAtypicalRepositoryId]);

    if (loading || loadingResults) return <Spinner />;
    if (error || errorResults) return <Error />;

    return (
        <RepositoryItemsFormContext.Provider
            value={{
                setContext: setState,
                setRepositoryItems: setRepositoryItems,
                stepType: stepType,
                repositoryType: state.repositoryType,
                repositoryItemType: state.repositoryType ? (getRepositoryItemType(stepType, state.repositoryType) || null) : null,
                atypicalRepositorySelectOption: state.atypicalRepositorySelectOption,
                atypicalRepository: state.atypicalRepository,
                atypicalRepositoryResults: state.atypicalRepositoryResults,
                filterItemsRelatedToActivities: state.filterItemsRelatedToActivities,
                filteredActivityIds: state.filteredActivityIds,
                customSkills: customSkills || [],
                descriptors: descriptors || [],
                activities: activities || [],
                tasks: tasks || [],
                atypicalItems: atypicalItems || [],
                roadmap: roadmap,
                enabledActivityRepository: enabledActivityRepository,
                enabledSkillRepositoryItems: enabledSkillRepositoryItems,
                enabledObservableElements: enabledObservableElements,
                repositoryLinks: data?.customRepository.repositoryLinks || [],
                linkItems: linkItems || [],
            }}
        >
            {/* ---------------------- 01 SELECT REPOSITORY TYPE ---------------------- */}
            <SelectRepositoryType
                position={startPosition}
                customRepository={data?.customRepository}
                activityResults={dataResults?.customRepositoryResults?.activityResults}
            />
            {/* ---------------------- 02 SELECT REPOSITORY ITEMS ---------------------- */}
            <SelectRepositoryItems
                position={startPosition + 1}
                customRepository={data?.customRepository}
                customRepositoryResults={dataResults?.customRepositoryResults}
            />
            {/* -------------------- 03 RATING -------------------- */}
            {(
                enabledRating
                && state.repositoryType
                && (
                    (customSkills && customSkills.length > 0)
                    || (descriptors && descriptors.length > 0)
                    || (activities && activities.length > 0)
                    || (tasks && tasks.length > 0)
                    || (atypicalItems && atypicalItems.length > 0 && state.atypicalRepository)
                    || (linkItems && linkItems.length > 0)
                )
            ) && (
                <RatingRepositoryItems
                    position={startPosition + (((activities && activities.length > 0) || (atypicalItems && atypicalItems.length > 0)) ? 2 : 3 )}
                    customRepository={data?.customRepository}
                />
            )}
        </RepositoryItemsFormContext.Provider>
    );
};
