import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Apollo
import { useQuery } from '@apollo/client';
import { ROADMAPS } from 'views/authenticated/roadmaps/gql-roadmaps';
import { SIMPLIFIED_ROADMAP } from 'views/authenticated/roadmaps/roadmap/gql-roadmap';

// Services
import { IRoadmap, ISelectOption } from 'services/interfaces';
import { getFormattedDate } from 'services/utils';

// Components
import Select, { ISelectProps } from 'components/react-select';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SELECT ROADMAP -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ISelectRoadmapProps extends ISelectProps {
    onChange: (newValue: any) => void;
    learnerId?: string;
    customRepositoryId?: string;
    sheetModelPlanificationId?: string;
    filterIds?: string[];
    defaultValue?: string;
}

export default function SelectRoadmap(props: ISelectRoadmapProps) {

    const { t } = useTranslation();
    const [options, setOptions] = useState<ISelectOption[]>([]);

    const { loading, data } = useQuery<{ roadmaps: { data: IRoadmap[] }}>(ROADMAPS, {
        variables: {
            page: 1,
            first: 100,
            learnerId: props.learnerId,
            customRepositoryId: props.customRepositoryId,
            sheetModelPlanificationId: props.sheetModelPlanificationId,
        },
    });

    const { data: dataDefault } = useQuery<{ roadmap: IRoadmap }>(SIMPLIFIED_ROADMAP, {
        variables: { id: props.defaultValue },
        skip: props.defaultValue == null,
    });

    useEffect(() => {
        if (data?.roadmaps) {

            let options = data.roadmaps.data.map(r => ({
                value: r.id,
                label: `${r.customRepository?.name} ${t('at.1')} ${r.company?.name} (${getFormattedDate(r.startDate)})`,
            }));

            if (props.filterIds) {
                options = options.filter(o => !props.filterIds?.includes(o.value));
            }

            setOptions(options);
        }
        if (dataDefault?.roadmap) {
            let r = dataDefault.roadmap;
            props.onChange({
                value: r.id,
                label: `${r.customRepository?.name} ${t('at.1')} ${r.company?.name} (${getFormattedDate(r.startDate)})`,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dataDefault, props.filterIds]);

    return (
        <Select
            {...props}
            placeholder={t('roadmaps')}
            onChange={value => props.onChange(value)}
            options={options}
            isLoading={loading}
        />
    );
}
